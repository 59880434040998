import { useMicroAppImport } from '@amzn/sitc-frontend-micro-app-sdk-core';
import { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import type { UniversalHeaderProps } from '@amzn/studios-universal-header-app-shared-types';
import React from 'react';

import { universalHeaderConfig } from '../utils/universal-header';

type UseUniversalHeader = (UniversalHeaderProps: UniversalHeaderProps) => void;

interface Props {
  radio: Radio;
}

export const UniversalHeaderInit: React.FC<Props> = ({ radio }) => {
  const { useUniversalHeader } = useMicroAppImport<{ useUniversalHeader: UseUniversalHeader }>(
    'amzn-studios-universal-header-app',
    './hooks'
  ).result;

  useUniversalHeader({ radio, microAppName: 'amzn-studios-demo-micro-app', universalHeaderConfig });

  return null;
};
