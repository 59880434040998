import '../i18n';

import { AuthSessionProvider, CommonUserPreferencesProvider, MicroAppUserPreferencesProvider } from '@amzn/sitc-frontend/contexts';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { getMicroAppApiBaseUrlFromTemplate } from '@amzn/sitc-frontend/utils';
import { MicroAppImportProvider } from '@amzn/sitc-frontend-micro-app-sdk-core';
import { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { preferencesSchema } from '@amzn/studios-demo-micro-app-types/preferences/user-preferences';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';

import { DemoUserPreferences } from '../types/user-preferences';
import { appDomainTemplate, DemoUserPreferenceContext } from '../utils/app';
import { AppLoading } from './AppLoading';
import { UniversalHeaderInit } from './UniversalHeaderInit';

const Index = React.lazy(() => import('./Index'));
const CommonUserPreferences = React.lazy(() => import('./CommonUserPreferences'));
const MicroAppUserPreferences = React.lazy(() => import('./MicroAppUserPreferences'));
const RevisionHistory = React.lazy(() => import('./RevisionHistory'));
const OriginSearch = React.lazy(() => import('./OriginSearch'));
const OriginDataGridSearch = React.lazy(() => import('./OriginDataGridSearch'));
const UniversalHeaderOriginSearch = React.lazy(() => import('./UniversalHeaderOriginSearch'));
const AuthSession = React.lazy(() => import('./AuthSession'));

const StudiosDemoMicroApp: React.FC<MicroAppInitializationProps> = (props) => {
  const { appId, authSession, basePath, userPreferences, radio, stage, theme = backlotLightTheme } = props;
  const microAppImports = [
    { microAppName: 'amzn-studios-origin-search-app', importPaths: ['./components'] },
    { microAppName: 'amzn-studios-universal-header-app', importPaths: ['./hooks'] },
  ];

  return (
    <MicroAppImportProvider microAppImports={microAppImports}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename={basePath}>
            <AuthSessionProvider authSession={authSession}>
              <CommonUserPreferencesProvider userPreferences={userPreferences} radio={radio}>
                <MicroAppUserPreferencesProvider<DemoUserPreferences>
                  appId={appId}
                  appLoader={<AppLoading />}
                  context={DemoUserPreferenceContext}
                  endpoint={`${getMicroAppApiBaseUrlFromTemplate(appDomainTemplate, stage)}/api/user-preferences`}
                  schema={preferencesSchema}>
                  <Box sx={{ backgroundColor: 'background.paper', paddingTop: '80px' }}>
                    <UniversalHeaderInit radio={radio as unknown as Radio} />
                    <Tabs value={false}>
                      <Tab
                        label="Common User Preferences"
                        value="/common-user-preferences"
                        to="/common-user-preferences"
                        component={Link}
                      />
                      <Tab
                        label="Micro-App User Preferences"
                        value="/micro-app-user-preferences"
                        to="/micro-app-user-preferences"
                        component={Link}
                      />
                      <Tab label="Revision History" value="/revision-history" to="/revision-history" component={Link} />
                      <Tab label="Origin Search" value="/origin-search" to="/origin-search" component={Link} />
                      <Tab
                        label="Origin Search Data Grid"
                        value="/origin-search-data-grid"
                        to="/origin-search-data-grid"
                        component={Link}
                      />
                      <Tab label="Auth Session" value="/auth-session" to="/auth-session" component={Link} />
                    </Tabs>
                    <Suspense fallback="loading...">
                      <Routes>
                        <Route path="/" element={<Index {...props} />} />
                        <Route path="/micro-app-user-preferences" element={<MicroAppUserPreferences />} />
                        <Route path="/common-user-preferences" element={<CommonUserPreferences />} />
                        <Route path="/revision-history" element={<RevisionHistory {...props} />} />
                        <Route path="/origin-search" element={<OriginSearch {...props} />} />
                        <Route path="/origin-search-data-grid" element={<OriginDataGridSearch />} />
                        <Route path="/universal-header-origin-search/:titlePart" element={<UniversalHeaderOriginSearch />} />
                        <Route path="/auth-session" element={<AuthSession />} />
                      </Routes>
                    </Suspense>
                  </Box>
                </MicroAppUserPreferencesProvider>
              </CommonUserPreferencesProvider>
            </AuthSessionProvider>
          </BrowserRouter>
        </I18nextProvider>
      </ThemeProvider>
    </MicroAppImportProvider>
  );
};

export default StudiosDemoMicroApp;
