export const en = {
  'micro_app.showing': 'Showing {{name}}',
  'micro_app.loading': 'Loading App',
  'micro_app.user_preferences': 'Micro-App User Preferences',
  'micro_app.common_user_preferences': 'Common User Preferences',
  'micro_app.revision_history': 'Revision History',
  'micro_app.revision_history.button.show_revision_history': 'Show Revision History',
  'micro_app.revision_history.input.source_slug': 'Source slug...',
  'micro_app.origin_search_data_grid.header': 'Origin Search Data Grid',
  'micro_app.origin_search.header.customized_search': 'Customized Search',
  'micro_app.origin_search.header.custom_results_handler': 'Custom Results Handler',
  'micro_app.origin_search.header.custom_query': 'Custom ISDG Query',
  'micro_app.origin_search.header.default_search': 'Default Search',
};
